import {
  useState,
  useMemo,
  useEffect,
  cloneElement,
  forwardRef,
  useContext,
  useRef,
  createContext,
  Children,
  ReactNode,
  isValidElement,
  ComponentType,
  HTMLAttributes,
} from 'react';
import clsx from 'clsx';
import ListSubheader from '@material-ui/core/ListSubheader';
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';

import Avatar from '../../../components/UI/Avatar';
import { Autocomplete } from '../../../components';
import TextField from '../../../components/UI/Inputs/TextField';
import Contact from '../../../../types/contact';
import Chip from './Chip';
import styles from './SearchContactInput.module.css';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import contactService from '../../../services/contacts/contactService';

interface Option extends Contact {
  combinedSearchText: string;
}
const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>(function GetType(props, ref) {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref?.current != null) {
      ref?.current?.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = Children?.toArray(children);
  const itemCount = itemData?.length;
  const itemSize = 48;

  const getChildSize = (child: ReactNode) => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData?.map(getChildSize)?.reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params?.key} component="div">
    {params?.group}
  </ListSubheader>,
  params?.children,
];
// this component should search contact by name and phone number
// for this functionality we have to combine options in the autocomplete
const combineSearchOption = (contact: Contact) => {
  const { name = '', suggestion_name = '', phones } = contact;

  const phoneNumber = phones[0]?.normalized_phone || '';

  const combinedSearchText = `${suggestion_name};${name};${phoneNumber}`;
  return {
    ...contact,
    combinedSearchText,
  };
};

export default function ContactInput({
  contacts,
  id,
  label,
  placeholder,
  error = false,
  helperText,
  value,
  onChange,
  disabled = false,
}: {
  contacts: Contact[];
  id: string;
  label: string;
  placeholder: string;
  error?: boolean;
  helperText?: string;
  value?: string;
  onChange?: (nextValue?: string) => void;
  disabled?: boolean;
}) {
  const options = useMemo(() => {
    return contacts?.map(combineSearchOption);
  }, [contacts]);

  const [searchQuery, setSearchQuery] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);

  const handleSearchInputChange = (_, newInputValue: string) => {
    if (value) {
      return;
    }

    setSearchQuery(newInputValue);
  };

  const handleChange = (_, nextValue: Option) => {
    onChange(nextValue.uuid);

    setSearchQuery('');
  };

  const currentOption = options.find(({ uuid }) => uuid === value);

  const shouldHideSearchValue = !!currentOption;

  return (
    <Autocomplete
      open={popupOpen}
      onOpen={() => {
        setPopupOpen(true);
      }}
      onClose={() => {
        setPopupOpen(false);
      }}
      options={options}
      value={currentOption}
      onChange={handleChange}
      inputValue={searchQuery}
      onInputChange={handleSearchInputChange}
      disabled={disabled}
      ListboxComponent={ListboxComponent as ComponentType<HTMLAttributes<HTMLElement>>}
      renderGroup={renderGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          label={label}
          placeholder={shouldHideSearchValue ? '' : placeholder}
          fullWidth
          error={error}
          helperText={helperText}
          inputProps={{
            ...params?.inputProps,
            className: clsx(
              (params.inputProps as any).className,
              shouldHideSearchValue && styles.searchInputHidden
            ),
            value: shouldHideSearchValue ? '' : (params?.inputProps as any).value,
            readOnly: shouldHideSearchValue,
          }}
          InputProps={{
            ...params?.InputProps,
            startAdornment: !!currentOption && (
              <Chip
                thumbnail={currentOption?.thumbnail}
                name={currentOption?.suggestion_name || currentOption?.name}
                email={currentOption?.emails?.[0]?.email}
                phoneNumber={currentOption?.phones?.[0]?.normalized_phone}
                onUnSelect={() => {
                  onChange(undefined);
                  setSearchQuery('');
                }}
                onClick={() => {
                  setPopupOpen(true);
                }}
                disabled={disabled}
              />
            ),
            className: clsx(params.InputProps.className, !!currentOption && styles.selected),
          }}
        />
      )}
      getOptionLabel={(option) => option?.combinedSearchText}
      renderOption={(contact) => {
        const nameToShow = contactService?.getContactName(contact);

        return (
          <div className={styles.option} title={nameToShow}>
            <Avatar
              className={styles.optionAvatar}
              thumbnail={contact?.thumbnail}
              name={nameToShow}
              size={24}
            />
            <span className={styles.optionText}>{nameToShow}</span>
          </div>
        );
      }}
    />
  );
}
